import {Button, toast} from '@cashiaApp/web-components';
import React, {useLayoutEffect} from 'react';
import {useNavigate, useOutletContext, useParams} from 'react-router-dom';

import {ReactComponent as CopyIcon} from '../../assets/icons/copy_white.svg';
import {ReactComponent as EmptyIcon} from '../../assets/icons/empty-sad-face.svg';
import {ReactComponent as RightArrowIcon} from '../../assets/icons/right-arrow.svg';
import {ReactComponent as User} from '../../assets/icons/user-square_pink.svg';
import CustomSpinner from '../../components/common/CustomSpinner';
import {useGetOrderQuery} from '../../graphql/generated';
import {OutletContextProps} from '../../layouts/MainLayout';
import formatDate from '../../utils/formatDate';

type StatusType = 'SUCCESSFUL' | 'PENDING' | 'FAILED';
type PaymentModeType = 'MPESA' | 'CARD';

function getStatusStyles(status: string, includeBg = false) {
  const textColors: Record<StatusType | 'default', string> = {
    SUCCESSFUL: 'text-brightGreen',
    PENDING: 'text-continousYellow',
    FAILED: 'text-brightRed',
    default: 'text-gray-500',
  };

  const bgColors: Record<StatusType | 'default', string> = {
    SUCCESSFUL: 'bg-brightGreen',
    PENDING: 'bg-continousYellow',
    FAILED: 'bg-brightRed',
    default: 'bg-black',
  };

  const statusUpper = status.toUpperCase() as StatusType;
  const textColor = includeBg
    ? 'text-white'
    : textColors[statusUpper] ?? textColors.default;
  const bgColor = includeBg ? bgColors[statusUpper] ?? bgColors.default : '';

  return `${textColor} ${bgColor}`;
}

const PaymentDetails = () => {
  const {paymentId} = useParams<{paymentId: string}>();
  const navigate = useNavigate();
  const {setHeaderComponent} = useOutletContext<OutletContextProps>();

  const {data: paymentData, loading} = useGetOrderQuery({
    variables: {
      id: paymentId,
    },
    skip: !paymentId,
  });

  const payment = paymentData?.order;

  useLayoutEffect(() => {
    setHeaderComponent(
      loading ? undefined : (
        <>
          <div className="h-[75px] max-md:h-[35px] flex items-center gap-5 max-md:hidden">
            <p
              className="cursor-pointer font-medium"
              onClick={() => navigate('/all-payments')}>
              Payments
            </p>
            <RightArrowIcon className="text-foggy font-medium" />
            <p className="text-foggy font-[500]">{payment?.reference || ''}</p>
          </div>
        </>
      )
    );

    return () => {
      setHeaderComponent(undefined);
    };
  }, [setHeaderComponent, navigate, payment?.reference, loading]);

  const getPaymentMode = (mode: PaymentModeType | undefined | null) => {
    if (!mode) return '';

    switch (mode) {
      case 'MPESA':
        return 'M-PESA';
      case 'CARD':
        return payment?.card?.issuer === 'VISA' ? 'Visa Card' : 'Mastercard';
      default:
        return mode;
    }
  };

  if (loading) {
    return <CustomSpinner />;
  }

  if (!payment) {
    return (
      <div className="flex flex-col items-center justify-center h-full">
        <EmptyIcon />
        <p className="my-5 text-[24px] font-[600]">It's lonely out here</p>
        <Button
          onClick={() => navigate('/all-payments')}
          className="h-[42px] w-[220px] bg-paleRed rounded-xl text-white flex gap-2 items-center z-[100]">
          Return to Payments
        </Button>
      </div>
    );
  }

  return (
    <div className="flex flex-col lg:flex-row w-full h-full p-4 lg:p-6 flex-1 lg:flex-0 lg:gap-8 overflow-auto lg:overflow-hidden">
      <div className="w-full lg:w-1/2">
        <div className="p-0 lg:p-2 pb-1">
          <div className="text-foggy">Amount</div>
          <div className="flex items-center justify-between">
            <h2 className="text-2xl lg:text-3xl text-black font-bold">
              {payment.cost?.currencyCode}{' '}
              {new Intl.NumberFormat('en-US').format(
                Number(payment.cost?.amountInCents / 100)
              )}
            </h2>
            <span
              className={`px-4 py-2 text-sm font-medium rounded-xl ${getStatusStyles(
                payment.state,
                true
              )}`}>
              {payment.state.charAt(0).toUpperCase() +
                payment.state.slice(1).toLowerCase()}
            </span>
          </div>
        </div>

        {/* Details Section */}
        <div className="p-2">
          <div className="space-y-0 divide-y">
            <div className="flex justify-between border-t items-center py-5">
              <span className="text-foggy">Transaction ID:</span>
              <span className="text-black font-medium">
                {payment.reference}
              </span>
            </div>

            <div className="flex justify-between items-center py-5">
              <span className="text-foggy">Method:</span>
              <div className="flex items-center gap-2">
                <span className="font-medium text-black">
                  {getPaymentMode(payment?.paymentMode)}
                </span>
              </div>
            </div>

            <div className="flex justify-between items-center py-5">
              <span className="text-foggy">Transaction fees:</span>
              <span className="font-medium text-black">0.00</span>
            </div>

            <div className="flex justify-between whitespace-nowrap items-center py-5">
              <span className="text-foggy">Date:</span>
              <span className="font-medium text-black">
                {payment?.createdAt
                  ? formatDate(new Date(payment.createdAt as string), {
                      withWeekday: true,
                      withShortMonth: true,
                      withDate: true,
                      withYear: true,
                      withTime: true,
                      hour12: true,
                      locales: 'en-US',
                    })
                  : '---'}
              </span>
            </div>

            <div className="flex justify-between items-center py-5">
              <span className="text-foggy">Status:</span>
              <span className={`font-medium ${getStatusStyles(payment.state)}`}>
                {payment.state.charAt(0).toUpperCase() +
                  payment.state.slice(1).toLowerCase()}
              </span>
            </div>

            {/* Sender Information */}
            <div className="pt-6 pb-2">
              <span className="text-foggy">From:</span>
              <div className="flex items-center gap-3 mt-3 p-4 border rounded-xl">
                <div className="p-2 rounded-full">
                  <User />
                </div>
                <div className="flex flex-col">
                  <span className="font-medium text-foggy">
                    {payment.firstName || 'Melissa'}{' '}
                    {payment.lastName || 'Mukami'}
                  </span>
                  {payment.phoneNumber && (
                    <span className="text-sm text-black font-medium">
                      {payment.phoneNumber.countryCode}{' '}
                      {payment.phoneNumber.number}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="hidden lg:block border-l border-dividerGrey min-h-[calc(100vh-48px)] -mt-6" />

      <div className="w-full lg:w-1/2 lg:p-2 flex h-full flex-col justify-between">
        <div className="flex-grow">
          <h2 className="text-foggy font-semibold pt-2 mb-4">ANALYTICS</h2>
          <div className="flex justify-between p-6 h-[120px] flex-grow flex-col rounded-xl border">
            <div className="mb-4 flex justify-between items-center">
              <p className="text-foggy mb-1">Payment type:</p>
              <p className="font-medium text-black">
                {getPaymentMode(payment?.paymentMode as PaymentModeType)}
              </p>
            </div>
            <div className="mb-4 flex justify-between items-center">
              {payment?.paymentMode === 'CARD' ? (
                <>
                  <p className="text-foggy mb-1">Card number:</p>
                  <p className="font-medium text-black">
                    {payment?.card?.number || '****'}
                  </p>
                </>
              ) : (
                <>
                  <p className="text-foggy mb-1">Phone number:</p>
                  <p className="font-medium text-black">
                    {payment?.phoneNumber?.countryCode}{' '}
                    {payment?.phoneNumber?.number}
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="mt-auto pt-8 flex justify-end">
          <Button
            onClick={async () => {
              await navigator.clipboard.writeText('support@cashia.com');
              toast.success('Support email copied!');
            }}
            className="h-[48px] w-full rounded-xl lg:w-[220px] bg-paleRed text-white flex gap-2 items-center z-[100]">
            support@cashia.com
            <CopyIcon className="w-4 h-4" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PaymentDetails;
